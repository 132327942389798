import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import PageBlock from "../../components/major-pieces/pageblock/PageBlock"
import ERIPageBlocks from "../../components/major-pieces/pageblock/ERIPageBlocks"
import getPageBlockFromData from "../../components/major-pieces/pageblock/PageBlock"
import CovidPlan from "../../../static/pdf/school/School-Covid-Plan-Update-11-9-20.pdf"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/school.css"
import "../../styles/header.css"

import JSONData from "../../../content/school/schoolData.json"

function ERI () {
	
	
	const query = graphql`
	      query {
	        allFile(
	          sort: { fields: name, order: ASC }
	          filter: { relativePath: { glob: "images/pages/school/*.*" } }
	        ) {
	          edges {
	            node {
	              id
	              name
	              childImageSharp {
	                fluid {
	                  ...GatsbyImageSharpFluid
	                }
	              }
	            }
	          }
	        }
	        
			}
	`



	let data = useStaticQuery(query)
	console.log(data)

	let images = data.allFile
	let sidebarData = JSONData.sidebarTextblocks;
	let links = JSONData.links;
	let subheaderData = JSONData.subheader; 

	
	const sidebarImage = images.edges.find(element => element.node.name === 'sidebar').node
	
	console.log("JSONData for school: ")
	console.log(JSONData)

	const campusImage = images.edges.find(element => element.node.name === 'corner').node

	const column1 = images.edges.find(element => element.node.name === '1').node
	const column2 = images.edges.find(element => element.node.name === '2').node
	const column3 = images.edges.find(element => element.node.name === '3').node


	const intensives = images.edges.find(element => element.node.name === 'intensives').node


	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content school-colors school">
				<div className = "school-main-area">
					<div className="subheader">
		   <h1 className="name"> 
		   		<div className="header-block">Saint John's <div className="header-block"></div></div>
	       		<div className="header-block">Orthodox Christian School</div>
	       </h1>
		</div>
					
				</div>
				<Sidebar pageHome="true" textData={sidebarData} links={links} image={sidebarImage}/>
				<a className="download-btn school-link" href="http://www.sjocs.org/" target="_blank">Go to School Website</a>

				<div className = "school-main-area">
					<BackgroundImage
		           		className="school-main-image"
		           		fluid={campusImage.childImageSharp.fluid}
		               	alt="" >
		               	<div className="text-overlay">
							“We welcome all students from preschool through eighth grade, 
							as we foster in them love of God, love of neighbor, and a love of learning.”
						</div>
					</BackgroundImage>

		               	<div className = "left-column">
		               			<Img className="column-image" fluid={column1.childImageSharp.fluid} />
								<Img className="column-image" fluid={column2.childImageSharp.fluid} />
								<Img className="column-image" fluid={column3.childImageSharp.fluid} />
		               	</div>
		               	<div className = "right-column">
		               	<Link className="download-btn school-link fellowship-button" to='fellowship'>NEW! Teaching Fellowship/Internship Program</Link>
						<a className="download-btn school-link covid-plan" href={CovidPlan} target="_blank">View School Covid Plan</a>

			               	<h3 style={{color:"black"}}>Preschool</h3>
							<p>Saint John's Preschool emphasizes social and emotional learning through a fun, yet structured routine of both free play and directed activities.</p>
							
							<h3 style={{color:"black"}}>Kindergarten and Elementary (1st-6th)</h3>
							<p>Our School has traditional classrooms with access to individual computers as needed.</p>
							<p>We use standard curriculum, such as Saxon Math and McGraw Hill for language.</p>
							<p>Students begin each day with a prayer and hear stories from the Bible once a week. Formal religious training is not part of our curriculum. Instead we hope our students and their parents sense God’s love through our staff’s care and a nurturing environment.</p>
							<h3 style={{color:"black"}}>Middle School (7th/8th)</h3>
							<p>Saint John’s Middle-School encourages critical thinking to integrate academic learning and real-world experience through week-long intensives, and to explore how Orthodox Christian values speak to contemporary issues.</p>


		               	</div>
  
				</div>
					

				
			</main>
			<Footer />		
		</div>
		)
}

export default ERI 